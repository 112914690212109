import React, { ChangeEvent, FormEvent } from "react";
import Colors from "../../constants/Colors";
import { FaSearch } from "react-icons/fa";
import CustomButton from "../buttons/CustomButton";
import Variables from "../../constants/Variables";
import { isMobile } from "react-device-detect";

interface ComponentProps {
  value: string;
  numberValue: string;
  handleChange: (event: FormEvent<HTMLInputElement>) => void;
  handleNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: any) => void;
  placeholder?: string;
}

const AnalysisSearchBar: React.FunctionComponent<ComponentProps> = ({
  value,
  numberValue,
  handleChange,
  handleNumberChange,
  handleSubmit,
  placeholder,
}) => {
  return (
    <div style={searchContainer}>
      <form style={inlineForm} onSubmit={handleSubmit}>
        <FaSearch style={searchIcon} size={22} />
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          style={textInput}
          onChange={handleChange}
        />
        <input
          type="number"
          value={numberValue}
          step="0.01"
          style={numberInput}
          onChange={handleNumberChange}
        />
        <CustomButton onClick={handleSubmit} />
      </form>
    </div>
  );
};

AnalysisSearchBar.defaultProps = {
  placeholder: "Search...",
} as Partial<ComponentProps>;

const textInput = {
  boxSizing: "border-box",
  fontSize: 18,
  fontFamily: Variables.fontFamilyText,
  background: Colors.white,
  color: Colors.grey,
  paddingTop: 15,
  paddingBottom: 12,
  paddingRight: 13,
  paddingLeft: 50,
  border: "none",
  outline: "none",
  width: isMobile ? "100%" : "60%",
  fontWeight: "normal",
  borderRadius: 5,
} as React.CSSProperties;

const numberInput = {
  boxSizing: "border-box",
  fontSize: 16,
  fontFamily: Variables.fontFamilyText,
  background: Colors.white,
  color: Colors.grey,
  paddingTop: 15,
  paddingBottom: 12,
  paddingRight: 13,
  paddingLeft: 10,
  marginTop: isMobile ? 10 : 0,
  marginLeft: isMobile ? 0 : 10,
  border: "none",
  outline: "none",
  width: isMobile ? "50%" : "15%",
  fontWeight: "normal",
  borderRadius: 5,
} as React.CSSProperties;

const searchContainer = {
  backgroundColor: Colors.themeColor,
  paddingBottom: 15,
  borderRadius: 5,
  display: "inline",
} as React.CSSProperties;

const searchIcon = {
  color: Colors.lightGrey,
  position: "absolute",
  left: 30,
  top: 17,
} as React.CSSProperties;

const inlineForm = {
  display: "flex",
  flexFlow: "row wrap",
} as React.CSSProperties;

export default AnalysisSearchBar;
