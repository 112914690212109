import React from "react";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";
import Dot from "../generalcomponents/Dot";

interface SectionHeadlineProps {
  children: string | string[];
  fontSize?: number;
  showDot: boolean;
  marginTop?: number;
}

const SectionHeadline: React.FunctionComponent<SectionHeadlineProps> = ({
  children,
  fontSize,
  showDot,
  marginTop,
}) => {
  const textStyle = {
    color: Colors.almostBlack,
    fontFamily: Variables.fontFamilyHeadline,
    fontWeight: Variables.fontWeightHeadline,
    fontSize: fontSize,
    lineHeight: 1.2,
    marginBottom: 10,
    marginTop: marginTop,
  };

  return (
    <p style={textStyle}>
      {children}
      {showDot ? <Dot fontSize={30} /> : ""}
    </p>
  );
};

SectionHeadline.defaultProps = {
  fontSize: 25,
  showDot: true,
  marginTop: 30,
} as Partial<SectionHeadlineProps>;

export default SectionHeadline;
