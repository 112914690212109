const sanitizeSpeech = (speech: string): string => {
  const sanitizedSpeech = speech
    .toLowerCase()
    .replaceAll(".", "")
    .replaceAll(",", "")
    .replaceAll("!", "")
    .replaceAll(";", "")
    .replaceAll(":", "")
    .replaceAll("'", "")
    .replaceAll('"', "")
    .replaceAll("”", "");

  return sanitizedSpeech;
};

const sanitizeInputString = (searchString: string): string =>
  searchString.toLowerCase().trim();

export const SanitizeService = {
  sanitizeSpeech,
  sanitizeInputString,
};
