import React from "react";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";
import Domains from "../../assets/domains.svg";
import Analysis from "../../assets/analysis.svg";
import Social from "../../assets/social.svg";

export default function Instructions() {
  return (
    <div style={instructionsContainer}>
      <div className="container">
        <div className="col-md-10 offset-md-1 text-center">
          <div className="row">
            <div className="col-md-4">
              <div className="instruction-card">
                <h3 style={instructionsHeader}>Indtast et ord</h3>
                <img style={image} src={Domains} alt="Indtast ord" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="instruction-card">
                <h3 style={instructionsHeader}>Få en analyse af ordet</h3>
                <img style={image} src={Analysis} alt="Analyse af ord" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="instruction-card">
                <h3 style={instructionsHeader}>Find forslag til odds</h3>
                <img style={image} src={Social} alt="Forslag til odds" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const instructionsContainer = {
  backgroundColor: Colors.lightThemeColor,
  paddingTop: 15,
  marginBottom: 20,
} as React.CSSProperties;

const instructionsHeader = {
  fontSize: 15,
  fontFamily: Variables.fontFamilyHeadline,
  fontWeight: Variables.fontWeightHeadline,
  color: Colors.themeColor,
  textTransform: "uppercase",
  paddingBottom: 10,
} as React.CSSProperties;

const image = {
  width: "100%",
  height: 100,
} as React.CSSProperties;
