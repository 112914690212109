import React, { useState, useEffect } from "react";
import MotionRow from "../generalcomponents/MotionRow";
import Card from "../cards/Card";
import CardTitle from "../typography/CardTitle";
import CardSubtitle from "../typography/CardSubtitle";
import { SpeechAnalysisService } from "../../services/SpeechAnalysisService";
import { Speech } from "../../models/Speech";
import { FaCheckCircle } from "react-icons/fa";
import Colors from "../../constants/Colors";

interface Props {
  word: string;
  speeches: Speech[];
  overUnder: number;
  text: string;
  bettingNumber: number;
}

export default function OverUnderCard({
  word,
  speeches,
  overUnder,
  text,
  bettingNumber,
}: Props) {
  const [showIcon, setShowIcon] = useState<boolean>(true);
  const [average, setAverage] = useState<string>("");

  useEffect(() => {
    const timesMentioned = SpeechAnalysisService.getTimesOverUnder(
      word,
      speeches,
      overUnder
    );

    const calculatedPercentage = (timesMentioned / speeches.length) * 100;
    const calculatedBetvalue = calculatedPercentage * bettingNumber;

    setAverage(calculatedPercentage.toFixed(2));

    if (bettingNumber !== 0 && calculatedBetvalue >= 102) {
      setShowIcon(true);
    }

    if (calculatedBetvalue < 102) {
      setShowIcon(false);
    }
  }, [word, speeches, overUnder, bettingNumber]);

  return (
    <MotionRow lg="3">
      <Card padding={30}>
        <CardTitle>{average} %</CardTitle>
        <CardSubtitle>{text}</CardSubtitle>
        {showIcon && (
          <FaCheckCircle
            style={iconStyles}
            size={35}
            color={Colors.themeColor}
          />
        )}
      </Card>
    </MotionRow>
  );
}

const iconStyles = {
  position: "absolute",
  right: 40,
  top: 33,
} as React.CSSProperties;
