import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import Card from "../cards/Card";
import UppercaseColoredText from "../typography/UppercaseColoredText";
import { Speech } from "../../models/Speech";
import PopularWordsItem from "./PopularWordsItem";
import { SpeechAnalysisService } from "../../services/SpeechAnalysisService";

interface ComponentProps {
  speeches: Speech[];
}

interface TableData {
  id: number;
  word: string;
  percentageThreeYears: string;
  percentageFiveYears: string;
  percentageEightYears: string;
  percentageTenYears: string;
  percentageTotalYears: string;
  playableUntilFiveYears: string;
}

const PopularWordsTable: React.FunctionComponent<ComponentProps> = ({
  speeches,
}) => {
  const [tableData, setTableData] = useState<TableData[]>([]);

  useEffect(() => {
    let popularWords: string[] = [
      "Verden",
      "Sygdom",
      "Prinsesse Marie",
      "Folk",
      "Europa",
      "Børnebørn",
      "Børn",
      "Prins Joachim",
      "Kronprinsparret",
      "Nytårshilsen",
      "Glæde",
      "Arbejde",
      "Politiet",
      "Ansvar",
      "Muligheder",
      "Omsorg",
      "Klima",
      "Generationer",
      "Verden",
      "Gamle",
      "Sydslesvig",
      "Fællesskab",
      "Nytårsaften",
      "Demokrati",
      "Coronaen",
      "Fred",
      "Sorg",
      "Krig",
    ];

    let speechTableData: TableData[] = [];
    let index: number = 1;

    popularWords.forEach((word) => {
      const timesMentionedThreeYears =
        SpeechAnalysisService.getYearlyTimesMentioned(
          word,
          speeches.slice(0, 3)
        );

      const timesMentionedFiveYears =
        SpeechAnalysisService.getYearlyTimesMentioned(
          word,
          speeches.slice(0, 5)
        );

      const timesMentionedEightYears =
        SpeechAnalysisService.getYearlyTimesMentioned(
          word,
          speeches.slice(0, 8)
        );

      const timesMentionedTenYears =
        SpeechAnalysisService.getYearlyTimesMentioned(
          word,
          speeches.slice(0, 10)
        );

      const timesMentionedTotal = SpeechAnalysisService.getYearlyTimesMentioned(
        word,
        speeches
      );

      const percentageThreeYears =
        (timesMentionedThreeYears / speeches.slice(0, 3).length) * 100;
      const percentageFiveYears =
        (timesMentionedFiveYears / speeches.slice(0, 5).length) * 100;
      const percentageEightYears =
        (timesMentionedEightYears / speeches.slice(0, 8).length) * 100;
      const percentageTenYears =
        (timesMentionedTenYears / speeches.slice(0, 10).length) * 100;
      const percentageTotal = (timesMentionedTotal / speeches.length) * 100;

      const playableUntil =
        percentageFiveYears !== 0
          ? (1 / (percentageFiveYears / 100)).toFixed(2)
          : "Ikke spilbar";

      let tableData: TableData = {
        id: index,
        word: word,
        percentageThreeYears: percentageThreeYears.toFixed(0),
        percentageFiveYears: percentageFiveYears.toFixed(0),
        percentageEightYears: percentageEightYears.toFixed(0),
        percentageTenYears: percentageTenYears.toFixed(0),
        percentageTotalYears: percentageTotal.toFixed(0),
        playableUntilFiveYears: playableUntil,
      };

      speechTableData.push(tableData);
    });

    setTableData(speechTableData);
  }, [speeches]);

  return (
    <Card>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>
              <UppercaseColoredText>Ord</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>3 år</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>5 år</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>8 år</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>10 år</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>Total</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>Spilbar til (5 år)</UppercaseColoredText>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.length > 0 &&
            tableData.map((item: TableData, key) => (
              <PopularWordsItem key={item.id} item={item} />
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default PopularWordsTable;
