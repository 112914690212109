import React, { useState, useEffect } from "react";
import Checkmark from "../../assets/checked.png";
import MotionRow from "../generalcomponents/MotionRow";
import Card from "../cards/Card";
import CardTitle from "../typography/CardTitle";
import CardSubtitle from "../typography/CardSubtitle";
import IconComponent from "../icons/IconComponent";
import { SpeechAnalysisService } from "../../services/SpeechAnalysisService";
import { Speech } from "../../models/Speech";

interface Props {
  word: string;
  speeches: Speech[];
  text: string;
}

export default function AverageMentionsCard({ word, speeches, text }: Props) {
  const [showIcon] = useState<boolean>(false);
  const [iconSource] = useState(Checkmark);
  const [average, setAverage] = useState<string>("");

  useEffect(() => {
    const timesMentioned = SpeechAnalysisService.getTimesMentioned(
      word,
      speeches
    );

    const calculatedAverage = timesMentioned / speeches.length;

    setAverage(calculatedAverage.toFixed(2));
  }, [word, speeches]);

  return (
    <MotionRow lg="3">
      <Card padding={30}>
        <CardTitle>{average}</CardTitle>
        <CardSubtitle>{text}</CardSubtitle>
        {showIcon && (
          <IconComponent
            right={40}
            top={30}
            src={iconSource}
            alt="analyse-ikon"
          />
        )}
      </Card>
    </MotionRow>
  );
}
