import { Speech } from "../models/Speech";
import { SanitizeService } from "./SanitizeService";

const getNumberOfWordsInSpeech = (speech: Speech): number =>
  speech.speech_text[0].text ? speech.speech_text[0].text.split(" ").length : 0;

const splitSpeechString = (speech: string): string[] => speech.split(" ");

const matchWordInArray = (word: string, speechArray: string[]): number =>
  speechArray.filter((x) => x === word.toLowerCase()).length;

const matchWordInString = (word: string, speechString: string): number =>
  speechString.split(word).length - 1;

const getTimesMentioned = (word: string, speeches: Speech[]): number => {
  const sanitizedWord = SanitizeService.sanitizeInputString(word);
  let timesMentioned = 0;

  if (sanitizedWord.includes(" ")) {
    speeches.forEach((speech) => {
      let mentions = getTimesMentionedInStringWithSpace(
        sanitizedWord + " ",
        speech.speech_text[0].text ? speech.speech_text[0].text : ""
      );
      timesMentioned = timesMentioned + mentions;
    });
  } else {
    speeches.forEach((speech) => {
      let mentions = getTimesMentionedInString(
        sanitizedWord,
        speech.speech_text[0].text ? speech.speech_text[0].text : ""
      );
      timesMentioned = timesMentioned + mentions;
    });
  }

  return timesMentioned;
};

const getYearlyTimesMentioned = (word: string, speeches: Speech[]): number => {
  const sanitizedWord = SanitizeService.sanitizeInputString(word);
  let timesMentioned = 0;

  if (sanitizedWord.includes(" ")) {
    speeches.forEach((speech) => {
      let mentions = getTimesMentionedInStringWithSpace(
        sanitizedWord + " ",
        speech.speech_text[0].text ? speech.speech_text[0].text : ""
      );

      if (mentions > 0) {
        timesMentioned = timesMentioned + 1;
      }
    });
  } else {
    speeches.forEach((speech) => {
      let mentions = getTimesMentionedInString(
        sanitizedWord,
        speech.speech_text[0].text ? speech.speech_text[0].text : ""
      );

      if (mentions > 0) {
        timesMentioned = timesMentioned + 1;
      }
    });
  }

  return timesMentioned;
};

const getTimesMentionedInString = (word: string, speech: string): number => {
  const sanitizedSpeech = SanitizeService.sanitizeSpeech(speech);
  const speechArray = splitSpeechString(sanitizedSpeech);
  const timesInSpeech = matchWordInArray(word, speechArray);

  return timesInSpeech;
};

const getTimesMentionedInStringWithSpace = (
  word: string,
  speech: string
): number => {
  const sanitizedSpeech = SanitizeService.sanitizeSpeech(speech);
  const timesInSpeech = matchWordInString(word, sanitizedSpeech);

  return timesInSpeech;
};

const getTimesOverUnder = (
  word: string,
  speeches: Speech[],
  overUnder: number
): number => {
  const sanitizedWord = SanitizeService.sanitizeInputString(word);
  let timesMentioned = 0;

  if (sanitizedWord.includes(" ")) {
    speeches.forEach((speech) => {
      let mentions = getTimesMentionedInStringWithSpace(
        sanitizedWord + " ",
        speech.speech_text[0].text ? speech.speech_text[0].text : ""
      );

      if (mentions > overUnder) {
        timesMentioned = timesMentioned + 1;
      }
    });
  } else {
    speeches.forEach((speech) => {
      let mentions = getTimesMentionedInString(
        sanitizedWord,
        speech.speech_text[0].text ? speech.speech_text[0].text : ""
      );

      if (mentions > overUnder) {
        timesMentioned = timesMentioned + 1;
      }
    });
  }

  return timesMentioned;
};

export const SpeechAnalysisService = {
  getNumberOfWordsInSpeech,
  getTimesMentioned,
  getTimesMentionedInString,
  getTimesMentionedInStringWithSpace,
  getYearlyTimesMentioned,
  getTimesOverUnder,
};
