export default {
  // Properties for shadow effect
  shadowOpacity: 0.8,
  shadowOpacityMiddle: 0.25,
  shadowOpacityLittle: 0.1,

  // Typography variables
  fontFamilyHeadline: "Gordita Bold",
  fontWeightHeadline: 800,
  fontFamilyText: "Gordita Regular",

  // Headline
  fontSizeHeadline: 40,

  // Subheader
  fontSizeSubtitle: 20,

  // Navigation variables
  fontSizeNavigation: 18,
  lineHeightNavigation: 50,
  zIndexNavigation: 1,
  fontWeightNavigation: "bold",
  textTransformNavigation: "uppercase",
  headerHeight: 50,
  headerPaddingBottom: 25,
};
