import React, { useState, useEffect } from "react";
import MotionRow from "../generalcomponents/MotionRow";
import Card from "../cards/Card";
import CardTitle from "../typography/CardTitle";
import CardSubtitle from "../typography/CardSubtitle";
import { SpeechAnalysisService } from "../../services/SpeechAnalysisService";
import { Speech } from "../../models/Speech";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";
import { IoIosCheckmarkCircle } from "react-icons/io";

interface Props {
  word: string;
  speeches: Speech[];
  text: string;
  bettingNumber: number;
}

export default function YearMentionsPercentageCard({
  word,
  speeches,
  text,
  bettingNumber,
}: Props) {
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<string>("");
  const [betvalue, setBetvalue] = useState<string>("100");

  useEffect(() => {
    const timesMentioned = SpeechAnalysisService.getYearlyTimesMentioned(
      word,
      speeches
    );

    const percentage = (timesMentioned / speeches.length) * 100;
    const calculatedBetvalue = percentage * bettingNumber;
    setBetvalue(calculatedBetvalue.toFixed(0));

    if (bettingNumber !== 0 && calculatedBetvalue >= 102) {
      setShowIcon(true);
    }

    if (calculatedBetvalue < 102) {
      setShowIcon(false);
    }

    setPercentage(percentage.toFixed(2));
  }, [word, speeches, bettingNumber]);

  return (
    <MotionRow lg="4">
      <Card padding={30}>
        <CardTitle>{percentage} %</CardTitle>
        <CardSubtitle>{text}</CardSubtitle>
        {showIcon && (
          <div style={betvalueContainer}>
            <IoIosCheckmarkCircle
              size={20}
              color={Colors.themeColor}
              style={iconStyles}
            />
            <p style={smallTextStyles}>Betvalue: {betvalue} %</p>
          </div>
        )}
      </Card>
    </MotionRow>
  );
}

const betvalueContainer = {
  backgroundColor: "#DDEDF5",
  borderRadius: 5,
  position: "absolute",
  right: 40,
  bottom: 50,
} as React.CSSProperties;

const smallTextStyles = {
  color: Colors.themeColor,
  fontSize: 12,
  fontFamily: Variables.fontFamilyHeadline,
  lineHeight: 1,
  paddingTop: 15,
  paddingBottom: 13,
  paddingRight: 15,
  paddingLeft: 35,
  marginBottom: 0,
} as React.CSSProperties;

const iconStyles = {
  position: "absolute",
  left: 10,
  bottom: 10,
} as React.CSSProperties;
