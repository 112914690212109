import React from "react";
import Headline from "../components/typography/Headline";
import Subtitle from "../components/typography/Subtitle";
import StandardText from "../components/typography/StandardText";
import Colors from "../constants/Colors";
import Dot from "../components/generalcomponents/Dot";
import Button from "../components/buttons/Button";
import SectionHeadline from "../components/typography/SectionHeadline";
import Card from "../components/cards/Card";
import MoveupContainer from "../components/generalcomponents/MoveupContainer";
import { GoogleAnalyticsService } from "../services/GoogleAnalyticsService";

class AboutPage extends React.Component {
  componentDidMount() {
    GoogleAnalyticsService.trackGoogleAnalyticsPageview(
      window.location.pathname,
      "PopularPage"
    );
  }

  render() {
    return (
      <div>
        <div style={headerBackground}>
          <div className="text-center container">
            <Headline>
              Om siden
              <Dot />
            </Headline>
            <Subtitle text="Aldrig flere odds i blinde" />
          </div>
          <br></br>
          <br></br>
        </div>
        <div style={contentBackground}>
          <div className="col-md-6 offset-md-3">
            <MoveupContainer moveupPadding={100}>
              <Card padding={35}>
                <StandardText>
                  Siden er lavet efter mange år med odds på nytårstalen. Med
                  komplet historik for talerne for du de bedste forudsætninger
                  for at finde værdi i dine odds.
                </StandardText>
                <SectionHeadline showDot={false} marginTop={20}>
                  Historik er ikke alt
                </SectionHeadline>
                <StandardText>
                  Siden her kan kun bruges til at bekræfte historikken for brug
                  af ord i talen. Dette betyder ikke, at der stilles nogen
                  garantier for at ordene nævnes. Det er ofte en god ide at
                  kombinere historisk data for talerne med begivenheder fra det
                  forgangne år.
                </StandardText>
                <SectionHeadline showDot={false} marginTop={20}>
                  Hvor kan man finde talerne?
                </SectionHeadline>
                <StandardText>
                  Du kan finde alle talerne på kongehusets hjemmeside, hvis du
                  gerne vil lave din egen analyse. Her findes taler siden 2001,
                  som er samme mængde medtaget på siden her.
                </StandardText>
                <br></br>
                <div className="col-md-6 offset-md-3 text-center">
                  <Button
                    text="Send en mail"
                    link="mailto:kristofferaandreasen@gmail.com"
                  />
                  <br></br>
                  <br></br>
                </div>
              </Card>
            </MoveupContainer>
          </div>
        </div>
      </div>
    );
  }
}

const headerBackground = {
  backgroundColor: Colors.themeColor,
  paddingTop: 50,
  paddingBottom: 50,
} as React.CSSProperties;

const contentBackground = {
  backgroundColor: Colors.lightThemeColor,
  paddingBottom: 30,
  paddingTop: 30,
} as React.CSSProperties;

export default AboutPage;
