import React from "react";
import Colors from "../../constants/Colors";
import SocialComponent from "../generalcomponents/SocialComponent";

const Footer: React.FunctionComponent = () => {
  return (
    <div>
      <div style={trademarkContainer}>
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <SocialComponent />
              <span style={trademarkText}>
                © Kristoffer Andreasen {new Date().getFullYear()}. All rights
                reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const trademarkContainer = {
  backgroundColor: Colors.lightThemeColor,
  paddingBottom: 20,
} as React.CSSProperties;

const trademarkText = {
  fontSize: 12,
  color: Colors.themeColor,
} as React.CSSProperties;

export default Footer;
