import { useSinglePrismicDocument } from "@prismicio/react";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";
import { MetaDataService } from "../../services/MetaDataService";
import "react-toastify/dist/ReactToastify.css";

interface Inputs {
  password: string;
}

interface Props {
  setIsCorrectPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PasswordForm({ setIsCorrectPassword }: Props) {
  const [document] = useSinglePrismicDocument("metadata");
  const [password, setPassword] = useState<string>("");
  const { register, handleSubmit } = useForm<Inputs>();

  useEffect(() => {
    if (document) {
      const metaData = MetaDataService.getMetaDataFromDocument(document);

      if (metaData && metaData.password[0].text) {
        setPassword(metaData.password[0].text);
      }

      if (metaData && metaData.password_required === false) {
        setIsCorrectPassword(true);
      }
    }
  }, [document, setIsCorrectPassword]);

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    if (data.password === password) {
      setIsCorrectPassword(true);
    }

    if (data.password !== password) {
      toast.error("Password is wrong!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div style={containerStyles}>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleSubmit(onSubmit)} style={inlineForm}>
        <FaLock style={searchIcon} size={22} />
        <input
          style={textInput}
          placeholder={"Skriv koden"}
          {...register("password")}
        />
        <input value={"Send"} type="submit" style={buttonStyle} />
      </form>
    </div>
  );
}

const containerStyles = {
  position: "absolute",
  display: "inline",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  zIndex: 100,
  marginTop: 40,
} as React.CSSProperties;

const inlineForm = {
  flexFlow: "row wrap",
} as React.CSSProperties;

const textInput = {
  boxSizing: "border-box",
  fontSize: 18,
  fontFamily: Variables.fontFamilyText,
  background: Colors.white,
  color: Colors.grey,
  paddingTop: 15,
  paddingBottom: 12,
  paddingRight: 13,
  paddingLeft: 50,
  outline: "none",
  width: "60%",
  fontWeight: "normal",
  borderRadius: 5,
  border: "1px solid #E8EAED",
} as React.CSSProperties;

const searchIcon = {
  color: Colors.grey,
  position: "relative",
  left: 40,
  bottom: 4,
} as React.CSSProperties;

const buttonStyle = {
  fontSize: 16,
  fontFamily: Variables.fontFamilyHeadline,
  color: Colors.themeColor,
  textAlign: "center",
  paddingTop: 17,
  paddingBottom: 14,
  paddingRight: 14,
  paddingLeft: 14,
  marginLeft: 10,
  marginTop: 0,
  backgroundColor: Colors.dotcolor,
  borderRadius: 5,
  width: 100,
  border: "none",
  cursor: "pointer",
  outline: "inherit",
} as React.CSSProperties;
