import ReactGA from "react-ga4";

/**
 * Initialize Google Analytics tracking
 */
const initGoogleAnalytics = (): void => {
  ReactGA.initialize("G-6FQHR62LHX");
};

/**
 * Track a pageview with path
 * and title.
 * @param {string} path
 * @param {string} title
 */
const trackGoogleAnalyticsPageview = (path: string, title: string): void => {
  ReactGA.send({ hitType: "pageview", page: path, title: title });
};

/**
 * Trigger event in Google Analytics
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 */
const triggerGoogleAnalyticsEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
): void => {
  ReactGA.event(action, {
    category: category,
    action: action,
    label: label,
    value: value,
  });
};

export const GoogleAnalyticsService = {
  initGoogleAnalytics,
  trackGoogleAnalyticsPageview,
  triggerGoogleAnalyticsEvent,
};
