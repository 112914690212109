import React, { useEffect } from "react";
import CardTitle from "../typography/CardTitle";

interface Props {
  item: TableData;
}

interface TableData {
  id: number;
  word: string;
  percentageThreeYears: string;
  percentageFiveYears: string;
  percentageEightYears: string;
  percentageTenYears: string;
  percentageTotalYears: string;
  playableUntilFiveYears: string;
}

const PopularWordsItem: React.FunctionComponent<Props> = ({ item }) => {
  useEffect(() => {}, []);

  return (
    <tr>
      <td>
        <CardTitle fontSize={17} marginBottom={0} lineHeight={1.8}>
          {item.word}
        </CardTitle>
      </td>
      <td>
        <h6 style={textStyling}>{item.percentageThreeYears} %</h6>
      </td>
      <td>
        <h6 style={textStyling}>{item.percentageFiveYears} %</h6>
      </td>
      <td>
        <h6 style={textStyling}>{item.percentageEightYears} %</h6>
      </td>
      <td>
        <h6 style={textStyling}>{item.percentageTenYears} %</h6>
      </td>
      <td>
        <h6 style={textStyling}>{item.percentageTotalYears} %</h6>
      </td>
      <td>
        <h6 style={textStyling}>{item.playableUntilFiveYears.toString()}</h6>
      </td>
    </tr>
  );
};

const textStyling = {
  fontSize: 17,
  paddingBottom: 0,
  marginBottom: 0,
  lineHeight: 1.8,
} as React.CSSProperties;

export default PopularWordsItem;
