import React from "react";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";
import DocumentSvg from "../../assets/document.svg";

export default function NoSpeechesFoundComponent() {
  return (
    <div style={instructionsContainer}>
      <div className="container">
        <div className="col-md-6 offset-md-3 text-center">
          <div className="row">
            <div className="col-md-12">
              <div className="instruction-card">
                <h3 style={instructionsHeader}>
                  Ingen taler fundet for dine filtre
                </h3>
                <p>Slå flere filtre til for at se resultater.</p>
                <img style={image} src={DocumentSvg} alt="Ingen taler fundet" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const instructionsContainer = {
  backgroundColor: Colors.lightThemeColor,
  paddingTop: 15,
  marginBottom: 20,
} as React.CSSProperties;

const instructionsHeader = {
  fontSize: 15,
  fontFamily: Variables.fontFamilyHeadline,
  fontWeight: Variables.fontWeightHeadline,
  color: Colors.themeColor,
  textTransform: "uppercase",
  paddingBottom: 10,
} as React.CSSProperties;

const image = {
  width: "100%",
  height: 100,
} as React.CSSProperties;
