import { PrismicDocument } from "@prismicio/types";
import { Speaker, Speech } from "../models/Speech";

const getSpeechesFromDocuments = (
  documents: PrismicDocument[] | undefined
): Speech[] => {
  let speechArray: Speech[] = [];

  if (documents && documents.length > 0) {
    documents.forEach((document) => {
      let unknown = document.data as unknown;
      let speech = unknown as Speech;

      speechArray.push(speech);
    });
  }

  const sortedSpeeches = speechArray.sort(
    (a, b) =>
      new Date(b.speech_date).getTime() - new Date(a.speech_date).getTime()
  );

  return sortedSpeeches;
};

const filterSpeechesForSpeakers = (
  speeches: Speech[],
  isQueenSelected: boolean,
  isKingSelected: boolean
): Speech[] => {
  let arrayWithFilteredQueen: Speech[] = [];
  let arraywithFilteredKing: Speech[] = [];

  if (isQueenSelected) {
    arrayWithFilteredQueen = speeches.filter(
      (item) => item.speaker === Speaker.DronningMargrethe
    );
  }

  if (isKingSelected) {
    arraywithFilteredKing = speeches.filter(
      (item) => item.speaker === Speaker.KongFrederik
    );
  }

  return arrayWithFilteredQueen.concat(arraywithFilteredKing);
};

export const SpeechDataService = {
  getSpeechesFromDocuments,
  filterSpeechesForSpeakers,
};
