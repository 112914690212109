import React from "react";
import Colors from "../../constants/Colors";
import { FaSearch } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import Variables from "../../constants/Variables";

interface ComponentProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const CustomButton: React.FunctionComponent<ComponentProps> = ({ onClick }) => {
  return (
    <button style={buttonStyle} onClick={(event) => onClick(event)}>
      Søg
      <FaSearch style={searchIcon} size={18} />
    </button>
  );
};

const buttonStyle = {
  fontSize: 16,
  fontFamily: Variables.fontFamilyHeadline,
  color: Colors.themeColor,
  textAlign: "center",
  paddingTop: 17,
  paddingBottom: 14,
  paddingRight: 14,
  paddingLeft: 14,
  marginLeft: 10,
  marginTop: isMobile ? 10 : 0,
  backgroundColor: Colors.dotcolor,
  borderRadius: 5,
  width: isMobile ? 120 : 100,
  border: "none",
  cursor: "pointer",
  outline: "inherit",
} as React.CSSProperties;

const searchIcon = {
  color: Colors.almostBlack,
  marginLeft: 10,
} as React.CSSProperties;

export default CustomButton;
