import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import Card from "../cards/Card";
import UppercaseColoredText from "../typography/UppercaseColoredText";
import TableItem from "./TableItem";
import { Speech } from "../../models/Speech";
import { SpeechAnalysisService } from "../../services/SpeechAnalysisService";
import { SanitizeService } from "../../services/SanitizeService";

interface ComponentProps {
  word: string;
  speeches: Speech[];
}

interface TableData {
  id: number;
  word: string;
  year: string;
  mentions: number;
  speaker: string;
}

const TableComponent: React.FunctionComponent<ComponentProps> = ({
  word,
  speeches,
}) => {
  const [tableData, setTableData] = useState<TableData[]>([]);

  useEffect(() => {
    const sanitizedWord = SanitizeService.sanitizeInputString(word);
    let speechTableData: TableData[] = [];
    let index: number = 1;

    speeches.forEach((element) => {
      if (sanitizedWord.includes(" ")) {
        let tableData: TableData = {
          id: index,
          word: sanitizedWord,
          year: element.speech_year[0].text ? element.speech_year[0].text : "",
          mentions: SpeechAnalysisService.getTimesMentionedInStringWithSpace(
            sanitizedWord + " ",
            element.speech_text[0].text ? element.speech_text[0].text : ""
          ),
          speaker: element.speaker,
        };

        speechTableData.push(tableData);
      } else {
        let tableData: TableData = {
          id: index,
          word: sanitizedWord,
          year: element.speech_year[0].text ? element.speech_year[0].text : "",
          mentions: SpeechAnalysisService.getTimesMentionedInString(
            sanitizedWord,
            element.speech_text[0].text ? element.speech_text[0].text : ""
          ),
          speaker: element.speaker,
        };

        speechTableData.push(tableData);
      }

      index = index + 1;
    });

    setTableData(speechTableData);
  }, [word, speeches]);

  return (
    <Card>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>
              <UppercaseColoredText>År</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>Antal gange nævnt</UppercaseColoredText>
            </th>
            <th>
              <UppercaseColoredText>Taler</UppercaseColoredText>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.length > 0 &&
            tableData.map((item: TableData, key) => (
              <TableItem key={item.id} item={item} />
            ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default TableComponent;
