export interface Edge {
  node?: Node;
}

export interface Node {
  uid?: string;
  url?: string;
  tags?: string[];
  last_publication_date?: string;
  first_publication_date?: string;
  data?: Speech;
  prismicId?: string;
  href?: string;
  lang?: Lang;
  id?: string;
}

export enum Lang {
  EnUs = "en-us",
}

export interface Speech {
  title: Heading[];
  speech_year: Heading[];
  speech_date: string;
  speech_language: SpeechLanguages;
  speech_country: SpeechCountries;
  speaker: Speaker;
  speech_text: Description[];
}

export enum SpeechLanguages {
  Danish = "Danish",
  English = "English",
}

export enum SpeechCountries {
  Denmark = "Denmark",
  UnitedKingdom = "United Kingdom",
  UnitedStates = "United States",
}

export enum Speaker {
  DronningMargrethe = "Dronning Margrethe",
  KongFrederik = "Kong Frederik 10.",
}

export interface Heading {
  text?: string;
  type?: TextType;
}

export interface Description {
  text?: string;
  type?: TextType;
  spans?: Span[];
  url?: string;
  alt?: string;
}

export enum TextType {
  Heading1 = "heading1",
  Heading2 = "heading2",
  Heading3 = "heading3",
  Heading4 = "heading4",
  Heading5 = "heading5",
  Heading6 = "heading6",
  ListItem = "list-item",
  Paragraph = "paragraph",
  Image = "image",
}

export interface Span {
  end?: number;
  start?: number;
  type?: SpanType;
}

export enum SpanType {
  Em = "em",
  Hyperlink = "hyperlink",
  Strong = "strong",
}
