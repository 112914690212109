import React from "react";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";

interface CardTitleProps {
  children: string | string[];
  fontSize?: number;
  marginBottom?: number;
  lineHeight?: number;
  verticalAlign?: string;
}

const CardTitle: React.FunctionComponent<CardTitleProps> = ({
  children,
  fontSize,
  marginBottom,
  lineHeight,
  verticalAlign,
}) => {
  const cardTitleStyling = {
    color: Colors.almostBlack,
    fontWeight: Variables.fontWeightHeadline,
    fontFamily: Variables.fontFamilyHeadline,
    fontSize: fontSize,
    lineHeight: lineHeight,
    marginBottom: marginBottom,
    verticalAlign: verticalAlign,
  };

  return <h3 style={cardTitleStyling}>{children}</h3>;
};

CardTitle.defaultProps = {
  fontSize: 20,
  marginBottom: 8,
  lineHeight: 1,
  verticalAlign: "middle",
} as Partial<CardTitleProps>;

export default CardTitle;
