import React, { useEffect } from "react";
import CardTitle from "../typography/CardTitle";

interface Props {
  item: TableData;
}

interface TableData {
  id: number;
  word: string;
  year: string;
  mentions: number;
  speaker: string;
}

const TableItem: React.FunctionComponent<Props> = ({ item }) => {
  useEffect(() => {}, []);

  return (
    <tr>
      <td>
        <CardTitle fontSize={17} marginBottom={0} lineHeight={1.8}>
          {item.year}
        </CardTitle>
      </td>
      <td>
        <h6 style={textStyling}>{item.mentions.toString()}</h6>
      </td>
      <td>
        <h6 style={textStyling}>{item.speaker}</h6>
      </td>
    </tr>
  );
};

const textStyling = {
  fontSize: 17,
  paddingBottom: 0,
  marginBottom: 0,
  lineHeight: 1.8,
} as React.CSSProperties;

export default TableItem;
