import React, { useState } from "react";
import Colors from "../../constants/Colors";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";

interface CardProps {
  imageSource: string;
  text: string;
  isSelected: boolean;
  handleClick: (event: any) => void;
}

const InlineImageCard: React.FunctionComponent<CardProps> = ({
  imageSource,
  text,
  isSelected = true,
  handleClick,
}) => {
  const [isHovered, setHovered] = useState(false);

  const cardStyling = {
    backgroundColor: `rgba(255, 255, 255, ${isHovered ? 0.8 : 0.7})`,
    padding: "10px",
    borderColor: isSelected ? Colors.dotcolor : Colors.themeColor,
    borderWidth: 3,
    borderRadius: 5,
    borderStyle: "solid",
    marginTop: "12px",
    cursor: "pointer",
  } as React.CSSProperties;

  const image = {
    width: "100%",
    height: 50,
    maxWidth: 50,
    borderRadius: 9999,
    verticalAlign: "middle",
  } as React.CSSProperties;

  const textBlockStyles = {
    justifyContent: "center",
    position: "relative",
    top: 8,
  } as React.CSSProperties;

  const textStyling = {
    color: Colors.almostBlack,
    fontFamily: "Gordita Bold",
    fontWeight: 800,
    fontSize: "15px",
    lineHeight: 1,
    paddingTop: "5px",
    marginLeft: "10px",
    marginBottom: "5px",
  } as React.CSSProperties;

  const subtitleStyles = {
    color: Colors.almostBlack,
    fontFamily: "Gordita Bold",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: 1,
    marginLeft: "10px",
    marginBottom: "1px",
  } as React.CSSProperties;

  const iconStyles = {
    position: "relative",
    bottom: 1,
    paddingRight: 2,
  } as React.CSSProperties;

  return (
    <div
      style={cardStyling}
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="d-inline-block">
        <img style={image} src={imageSource} alt="Dronning Margrethe" />
      </div>
      <div className="d-inline-block" style={textBlockStyles}>
        <p style={textStyling}>{text}</p>
        <p style={subtitleStyles}>
          {isSelected ? (
            <FaCheckCircle style={iconStyles} color={Colors.themeColor} />
          ) : (
            <FaTimesCircle style={iconStyles} color={Colors.red} />
          )}
          {isSelected ? "Inkluderet" : "Filtreret fra"}
        </p>
      </div>
    </div>
  );
};

InlineImageCard.defaultProps = {
  padding: 20,
  marginBottom: 20,
  borderRadius: 15,
} as Partial<CardProps>;

export default InlineImageCard;
