import { PrismicDocument } from "@prismicio/types";
import { MetaData } from "../models/MetaData";

const getMetaDataFromDocument = (
  document: PrismicDocument | undefined
): MetaData | undefined => {
  if (document && document.data) {
    const unknown = document.data as unknown;
    return unknown as MetaData;
  }

  return undefined;
};

export const MetaDataService = {
  getMetaDataFromDocument,
};
