import React from "react";
import Colors from "../../constants/Colors";
import Variables from "../../constants/Variables";

interface CardsubtitleProps {
  children: string;
  fontSize?: number;
}

const CardSubtitle: React.FunctionComponent<CardsubtitleProps> = ({
  children,
  fontSize,
}) => {
  const cardSubtitle = {
    color: Colors.grey,
    fontSize: fontSize,
    fontFamily: Variables.fontFamilyText,
    lineHeight: 1,
    marginBottom: 0,
  };

  return <p style={cardSubtitle}>{children}</p>;
};

CardSubtitle.defaultProps = {
  fontSize: 15,
} as Partial<CardsubtitleProps>;

export default CardSubtitle;
